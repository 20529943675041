<template>
  <div class="home">
    <div class="cont">
      <ul>
        <li v-for="(item, index) in dataList" :key="item.id">
          <h4>{{item.name}}</h4>
          <van-grid :border="false" square>
            <van-grid-item v-for="(item2, index) in item.children" :key="item2.id">
              <van-image :src="index >= 4 ? require(`@/assets/img/onePass${ (index % 4) + 1}.png`) : require(`@/assets/img/onePass${index + 1}.png`)" :style="`color: ${RandomColor(index)}`"/>
              <i>{{item2.iconWord}}</i>
              <p>{{item2.name}}</p>
            </van-grid-item>
          </van-grid>
          <div class="border" v-if="index < dataList.length - 1"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>
<script>
export default {
  data () {
    return {
      page: 1,
      limit: 1,
      id: null,
      appVisible: false,
      dataList: []
    }
  },
  mounted () {
    window.addEventListener('message', (event) => {
      if (event.data == 'close') {
        this.appVisible = false
      }
    })
  },
  methods: {
    RandomColor (i) {
      let r, g, b
      r = Math.floor(Math.random() * 256)
      g = Math.floor(Math.random() * 256)
      b = Math.floor(Math.random() * 256)
      return 'rgb(' + r + ',' + g + ',' + b + ')'
    },
    init () {
      this.$httpBack({
        url: this.$httpBack.adornUrl('/app/customizeMenuItem/uht/list'),
        method: 'get',
        params: this.$httpBack.adornParams({
          'id': this.id,
          'clientType': 2
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.menuList
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    itemClick (url, id) {
      if (url.indexOf('/') == 0) {
        var ua = window.navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i) == 'micromessenger'){
          wx.miniProgram.getEnv(function(res) {
            if (res.miniprogram) {
              wx.miniProgram.navigateTo({url: url})
            }else {
              this.$router.push(url)
            }
          })
        }else{
          this.$router.push(url)
        }
      } else if (url.indexOf('http') == 0) {
        this.url = url
        this.id = id
        this.$router.push({path: '/appSSO', query: {url: url, appId: id}})
      }
    }
  },
  created () {
    this.id = this.$route.query.id
    this.init()
  }
}
</script>
<style lang="scss" scoped>
::v-deep .van-grid-item__content {
  padding: 0;
}
.home {
  .fr {
    float: right !important;
  }
  .cont {
    overflow: hidden;
    // padding: 40px;
    background-color: #fff;
    ul {
      width: 100%;
      li {
        i {
          position: absolute;
          top: 20%;
          right: auto;
          font-style: normal;
          font-size: 32px;
          color: #fff;
          opacity: 0.8;
        }
        .van-image {
          width: 80px;
          height: 80px;
        }
        h4 {
          font-size: 30px;
          margin: 30px 44px;
          // margin-bottom: 30px;
        }
        .form-color {
          border-radius: 20px;
          position: relative;
          width: 100px;
          height: 100px;
        }
        .form-icon {
          color: #fff;
          font-size: 58px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        p {
          font-size: 26px;
        }
      }
    }
  }
}
.border {
  width: 100%;
  height: 20px;
  background-color: #f1f2f7;
}
</style>
